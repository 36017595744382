.js .loading::before,
.js .loading::after {
  content: "";
  @apply fixed block z-[3000];
}

.js .loading::before {
  @apply top-0 left-0 h-full w-full bg-dessus_dark_grey;
}

.js .loading::after {
  @apply h-8 w-8 rounded-full top-8 right-8 translate-y-0;
  border-top: 2px solid #ffffff;
  border-right: 2px solid transparent;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
