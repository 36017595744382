*,
*::after,
*::before {
  @apply box-border;
}

html,
body {
  @apply m-0 min-h-[100dvh];
}

body {
  @apply antialiased text-white bg-black font-sans overflow-x-hidden;
  -webkit-tap-highlight-color: transparent;
}

header {
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(8px) saturate(180%);
  -webkit-backdrop-filter: blur(8px) saturate(200%);
}

.video-mask {
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%
  );
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 50%
  );
}
