body #gform_1 {
  @apply w-full space-y-4 max-w-md mx-auto;
}

body #gform_1 input[type="text"],
body #gform_1 input[type="email"] {
  @apply bg-white rounded-sm border-none text-black text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder:text-black;
}

body #gform_1 .gform_footer button,
body #gform_1 .gform_footer input,
body #gform_1 .gform_page_footer button,
body #gform_1 .gform_page_footer input {
  @apply inline-flex mx-auto items-center gap-2 text-black border-none bg-gradient-to-r from-dessus_orange_dark to-dessus_orange_light focus:ring-4 focus:outline-none focus:ring-dessus_orange_light/50 font-medium rounded-full text-base px-5 py-2.5 origin-center transition-transform ease-in-out duration-300 hover:scale-95;
}

body #gform_1 .ginput_container_consent {
  @apply text-sm;
}

body #gform_1 .gfield_required {
  @apply sr-only;
}

body #gform_1 .gform_footer,
body #gform_1 .gform_page_footer {
  @apply text-center p-0 m-0;
}

.gform_wrapper.gravity-theme .gform_validation_errors {
  @apply w-full space-y-4 max-w-md mx-auto !text-left !bg-transparent;
}

.gform_wrapper.gravity-theme .gfield_validation_message,
.gform_wrapper.gravity-theme .validation_message {
  @apply w-full !text-sm rounded max-w-md mx-auto !text-left !bg-transparent;
}

.gform_wrapper.gravity-theme label {
  @apply !text-white;
}
